<template>
  <div id="user-profile">
    <section id="profile-info">
      <b-row>
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <b-card
            mg-alt="Profile Cover Photo"
            img-top
            class="card-profile mt-5"
          >
            <div class="profile-image-wrapper">
              <div class="profile-image p-0">
                <b-avatar
                  size="114"
                  :variant="skin"
                  :src="require('@/assets/images/avatars/profile.png')"
                />
              </div>
            </div>
            <h3>
              {{ user.given_name
              }}{{ user.middle_name ? ` ${user.middle_name} ` : ' '
              }}{{ user.family_name }}
            </h3>
            <h6 class="text-muted">
              {{ user.address.locality }},
              {{ user.address.country }}
            </h6>
            <b-badge
              class="profile-badge"
              :variant="`light-${user.active ? 'success' : 'danger'}`"
            >
              {{ user.active ? 'active' : 'inactive' }}
            </b-badge>
            <hr class="mb-2" />
            <div
              v-for="(data, key) in userData"
              :key="data.phone"
              class="mt-2"
            >
              <h5 class="text-capitalize mb-75">
                {{ key }}
              </h5>
              <b-card-text>
                {{ data }}
              </b-card-text>
            </div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-2"
              @click.prevent="
                $router.push({
                  name: 'update-profile',
                })
              "
            >
              Edit Profile
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAvatar,
  BCardText,
  BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userData() {
      const { email, phone_number } = this.user
      return {
        // eslint-disable-next-line camelcase
        email,
        phone: phone_number,
      }
    },
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
